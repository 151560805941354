export const JWT = 'JWT';

//Authentication helper file used to store/delete token
const Auth = {
  storeToken(token) {
    return localStorage.setItem(JWT, token);
  },

  clearSession() {
    localStorage.removeItem(JWT);
    localStorage.removeItem('unlocode');
  },

  getToken() {
    return localStorage.getItem(JWT);
  },

  getHeaders() {
    const token = this.getToken();
    return {
      portcallauthtoken: `${token}`,
    };
  },
};

export default Auth;
