import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { format } from 'date-fns';
import { updateIsps, selectSolas } from '../../../slices/portCallSlice';
import {
  DEFAULT_FORMAT_DATETIME,
  isCrossedDates,
  isDateFromOrDateToOverlapWithAnyDate,
} from '../../../../global/utils/datetimes';
import Table from '../../../../tables/components/Table';
import Button from '../../../../layout/components/Button';
import plusIcon from '../../../../tables/styles/images/plusIcon.svg';
import Modal from '../../../../layout/components/Modal';
import Input from '../../../../forms/components/Input';
import DatePicker, { DEFAULT_DATETIME_MASK } from '../../../../forms/components/DatePicker';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import '../styles/solas-security-measures.scss';
import { validateSchema } from '../../../../global/utils/validateForms';
import ConfirmationModal from '../../../../layout/components/ConfirmationModal';
import { cleanEmptyData } from '../../../../global/utils/cleanEmptyData';
import * as Yup from 'yup';
import MultiSelect from '../../../../forms/components/MultiSelect';
import { v4 as uuidv4 } from 'uuid';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Field } from '@navozyme/uikit/dist/atoms/Field';

const shipToShipColumns = [
  {
    Header: 'Date from',
    accessor: 'dateFrom',
    Cell: (props) => {
      return Boolean(props.value) ? format(new Date(props.value), DEFAULT_FORMAT_DATETIME) : '';
    },
  },
  {
    Header: 'Date to',
    accessor: 'dateTo',
    Cell: (props) => {
      return Boolean(props.value) ? format(new Date(props.value), DEFAULT_FORMAT_DATETIME) : '';
    },
  },
  {
    Header: 'Location or Latitude & Longitude',
    accessor: 'location',
    wrap: true,
  },
  {
    Header: 'Ship-to-ship activity',
    accessor: 'activity',
  },
  {
    Header: 'Security measure (only if SSP was not followed)',
    accessor: 'securityMeasure',
  },
];

function ShipToShipOperationsTable() {
  const dispatch = useDispatch();
  const params = useParams();
  const solas = useSelector(selectSolas);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastPortCalls, setLastPortCalls] = useState([]);
  const [refreshUuid, setRefreshUuid] = useState(uuidv4());

  useEffect(() => {
    if (solas?.data?.last10PortCalls) {
      setLastPortCalls(
        solas?.data?.last10PortCalls
          .filter((portcall) => portcall?.arrivalDate && portcall?.departureDate)
          .map((portcall) => {
            return {
              label: `${portcall?.portName}, ${portcall?.portCountry}`,
              value: portcall,
            };
          })
      );
    }
  }, [solas?.data?.last10PortCalls]);

  let initialValues = {};

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const validationSchema = Yup.object({
    dateFrom: Yup.string().required('*This field is required'),
    dateTo: Yup.string().required('*Please select one'),
    activity: Yup.string().required('*This field is required'),
    location: Yup.string().required('*This field is required'),
    securityMeasure: Yup.string().required('*This field is required'),
  });

  const handleSubmit = async (values, onSubmitProps) => {
    const finalValues = {
      ...values,
      dateFrom: Boolean(values.dateFrom) ? values.dateFrom.toISOString() : '',
      dateTo: Boolean(values.dateTo) ? values.dateTo.toISOString() : '',
      portcall: undefined,
    };

    cleanEmptyData(finalValues);
    if (isEditingRow) {
      const newMembers = solas.data.shipToShipOperations.map((member, i) => {
        if (i === editingRow) {
          return finalValues;
        }
        return member;
      });

      dispatch(
        updateIsps({
          ispsData: {
            shipToShipOperations: newMembers,
          },
          uid: params.uid,
        })
      );

      setIsModalOpen(false);
      setEditingRow(null);
    } else {
      let shipToShipOperations = [];
      if (solas.data.shipToShipOperations) {
        shipToShipOperations = solas.data.shipToShipOperations.concat(finalValues);
      } else {
        shipToShipOperations.push(finalValues);
      }
      dispatch(
        updateIsps({
          ispsData: {
            shipToShipOperations: shipToShipOperations,
          },
          uid: params.uid,
        })
      );
      setIsModalOpen(false);
    }
  };

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const row = isEditingRow ? solas.data.shipToShipOperations[editingRow] : {};
  initialValues = {
    dateFrom: row.dateFrom ? new Date(row.dateFrom) : null,
    dateTo: row.dateTo ? new Date(row.dateTo) : null,
    location: row.location ? row.location : '',
    activity: row.activity ? row.activity : '',
    securityMeasure: row.securityMeasure ? row.securityMeasure : '',
    portcall: lastPortCalls.find((portcall) =>
      isDateFromOrDateToOverlapWithAnyDate(
        new Date(row?.dateFrom),
        new Date(portcall?.value?.arrivalDate),
        new Date(portcall?.value?.departureDate)
      )
    )?.value,
  };

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false,
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  const validateDates = (values, setValues) => {
    if (isCrossedDates(new Date(values?.dateFrom), new Date(values?.dateTo))) {
      setValues({
        ...values,
        dateFrom: new Date(values?.portcall?.arrivalDate),
        dateTo: new Date(values?.portcall?.departureDate),
      });
    } else if (
      !isDateFromOrDateToOverlapWithAnyDate(
        new Date(values?.dateFrom),
        new Date(values?.portcall?.arrivalDate),
        new Date(values?.portcall?.departureDate)
      )
    ) {
      setValues({
        ...values,
        dateFrom: new Date(values?.portcall?.arrivalDate),
        dateTo: new Date(values?.portcall?.departureDate),
      });
    } else {
      setValues({ ...values, dateFrom: values?.dateFrom, dateTo: values?.dateTo });
    }
    setRefreshUuid(uuidv4());
  };

  return (
    <div className='solas-port-calls-table'>
      <Modal
        className={`add-ship-to-ship-modal`}
        title={'Add a ship-to-ship operation'}
        showModal={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false);
          if (isEditingRow) {
            setTimeout(() => setEditingRow(null), 200);
          }
        }}>
        <ConfirmationModal
          showModal={isConfirmationModalOpen}
          handleCloseModal={setIsConfirmationModalOpen}
          willDisableSave={formValues}
          onAcceptModal={() => {
            setIsConfirmationModalOpen(false);
            setIsModalOpen(false);
            setEditingRow(null);
            handleSubmit(formValues);
          }}
          className=''
        />
        <div className='add-ship-to-ship-modal__content'>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validate={(values) => {
              return validateSchema(
                values,
                validationSchema,
                setFormValues,
                setIsConfirmationModalOpen,
                setIsModalOpen
              );
            }}>
            {({ values, setValues, errors }) => {
              return (
                <Form className='add-ship-to-ship-form'>
                  <div className='add-ship-to-ship-form__row'>
                    <MultiSelect
                      key={
                        lastPortCalls?.length +
                        values?.portcall?.departureDate +
                        values?.portcall?.arrivalDate +
                        'portcall'
                      }
                      name='portcall'
                      options={lastPortCalls}
                      isMulti={false}
                      label='Select port call'
                      placeholder='Choose one'
                      onChange={(value) => {
                        setValues({
                          ...values,
                          dateFrom: new Date(value?.value?.arrivalDate),
                          dateTo: new Date(value?.value?.departureDate),
                        });
                      }}
                    />
                    <Field label='Date From' className='field_date_picker'>
                      <DatePickerTimeInput
                        key={initialValues?.dateFrom + values?.portcall?.arrivalDate + 'dateFrom' + refreshUuid}
                        name='date'
                        sizeInput='large'
                        maxDate={new Date(values?.portcall?.departureDate)}
                        minDate={new Date(values?.portcall?.arrivalDate)}
                        showTime={true}
                        defaultValue={values?.dateFrom}
                        action={({ isoDate }) =>
                          validateDates({ ...values, dateFrom: new Date(isoDate), dateTo: values?.dateTo }, setValues)
                        }
                        hasError={errors?.dateFrom}
                        errorMessage={errors?.dateFrom}
                      />
                    </Field>
                    <Field label='Date To' className='field_date_picker'>
                      <DatePickerTimeInput
                        key={initialValues?.dateTo + values?.portcall?.departureDate + 'dateTo' + refreshUuid}
                        name='date'
                        sizeInput='large'
                        maxDate={new Date(values?.portcall?.departureDate)}
                        minDate={new Date(values?.portcall?.arrivalDate)}
                        showTime={true}
                        defaultValue={values?.dateTo}
                        action={({ isoDate }) =>
                          validateDates({ ...values, dateTo: new Date(isoDate), dateFrom: values?.dateFrom }, setValues)
                        }
                        hasError={errors?.dateTo}
                        errorMessage={errors?.dateTo}
                      />
                    </Field>
                  </div>
                  <div className='add-ship-to-ship-form__row'>
                    <Input
                      name='location'
                      placeholder='Type one'
                      label='Location or Latitude & Longitude'
                      disabled={!values?.portcall}
                    />
                    <Input name='activity' placeholder='Type one' label='Ship-to-ship activity' />
                  </div>
                  <div className='add-ship-to-ship-form__row'>
                    <Input
                      name='securityMeasure'
                      placeholder='Type one'
                      label='Security measure (only if SSP was not followed)'
                    />
                  </div>
                  {isEditingRow ? (
                    <div className='add-ship-to-ship__form-submission-btns'>
                      <div
                        className='discard-btn'
                        onClick={() => {
                          setIsModalOpen(false);
                          setTimeout(() => setEditingRow(null), 300);
                        }}>
                        Discard
                      </div>
                      <Button type='submit'>Change Information</Button>
                    </div>
                  ) : (
                    <div className='add-ship-to-ship__form-submission-btns'>
                      <Button iconLeft={plusIcon} type='submit'>
                        Add
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={
          <h3>
            Are you sure you want to{' '}
            <span>
              delete all <br />
              ship-to-ship operations
            </span>{' '}
            from the list?
          </h3>
        }
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          dispatch(
            updateIsps({
              ispsData: {
                shipToShipOperations: [],
              },
              uid: params.uid,
            })
          );
          setShowConfirmDeleteAllMembersModal(false);
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this ship-to-ship operation?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onDeleteClick={() => {
          const shipToShipOperations = [...solas.data.shipToShipOperations];
          shipToShipOperations.splice(confirmDeleteMemberModalData.index, 1);

          dispatch(
            updateIsps({
              ispsData: {
                shipToShipOperations,
              },
              uid: params.uid,
            })
          );

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <h4>Please indicate the ship-to-ship operations carried out during the last 10 port calls. </h4>
      <p>In case the SSP procedures were not followed, please indicate it in the table.</p>
      <Table
        useRowNumbers
        title={'Ship-to-ship operations'}
        addButtonText={'Add ship-to-ship operation'}
        data={solas.data.shipToShipOperations || []}
        columns={shipToShipColumns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex,
          });
        }}
      />
    </div>
  );
}

export default ShipToShipOperationsTable;
