import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import auth from '../../../config/auth';
import { axiosInstance } from '../../../config/axios';

export type Company = {
  uid: string;
  name: string;
  [key: string]: any;
};

export type SelectCompanyState = {
  companies: Company[];
  loading: boolean;
};

const initialState: SelectCompanyState = {
  companies: [],
  loading: false,
};

export const getCompanies = createAsyncThunk(
  'company/getCompanies',
  async ({ name, companyType }: { name: string; companyType: string }) => {
    const headers = auth.getHeaders();
    const unlocode = localStorage.getItem('unlocode');

    let response = await axiosInstance.get(`/companies?companyType=${companyType}&unlocode=${unlocode}&name=${name}`, {
      headers,
    });
    return response.data.data;
  }
);

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.loading = false;
    });
    builder.addCase(getCompanies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanies.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectCompaniesState = (state: any): SelectCompanyState => state.companies;
export const selectCompanies = (state: any): Company[] => state.companies.companies;
export const selectCompaniesLoading = (state: any): boolean => state.companies.loading;

export default companySlice.reducer;
