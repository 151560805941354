import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { theme } from "@navozyme/uikit/dist/theme";
import { NavozymeProvider } from "@navozyme/uikit/dist/NavozymeProvider";
import { store } from './app/store';
import Routes from './Routes';
import * as Sentry from "@sentry/react";
import './index.css';

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || process.env.NODE_ENV;

Sentry.init({
    dsn: "https://d0377cc7103b28beb1735875c2d7c333@o4508486057590784.ingest.de.sentry.io/4508665673678928",
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <NavozymeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </NavozymeProvider>
  </React.StrictMode>
);
